import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

interface Post {
    excerpt: string;
    frontmatter: {
        title: string;
        description: string;
        date: string;
        slug: string;
        cover: any;
        country: {
            name: string;
        };
    };
}

interface PostListProps {
    posts: Post[];
}

const PostList: React.FC<PostListProps> = ({ posts }) => (
    <div className="post-list">
        {posts.map(post => (
            <div key={post.frontmatter.slug}>
                <Link to={'/' + post.frontmatter.slug}>
                    <GatsbyImage
                        className="cover"
                        image={getImage(post.frontmatter.cover)!}
                        alt={post.frontmatter.title}
                    />
                    <div className="date">
                        <small>{post.frontmatter.date}</small>
                    </div>
                    <div className="country">{post.frontmatter.country.name}</div>
                    <h4 className="title">
                        <span itemProp="headline">{post.frontmatter.title}</span>
                    </h4>
                    <section className="text">{post.frontmatter.description || post.excerpt}</section>
                    <span className="button">Read more</span>
                </Link>
            </div>
        ))}
    </div>
);

export default PostList;
